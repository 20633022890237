@import '../../assets/global';

.nav {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.navLogo {
  svg {
    height: 100px;
  }
  a {
    color: gray;
    font-size: small;
  }

  span {
    color: $primary;
    font-weight: 500;
    font-size: small;
  }
}

.navMenu {
  background-color: transparent;
  border: none;
}
