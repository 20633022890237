@import '../../assets/global';

.card {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  cursor: pointer;
  @include transition();

  &::before {
    content: '';
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    @include transition();
    @include gradient($from: $primary, $to: lighten($primary, 10));
  }

  .more {
    .arrow {
      position: relative;
      display: inline-block;
      margin-left: 5px;
      width: 24px;
      line-height: 24px;
      font-size: 12px;
      text-align: center;
      z-index: 2;
      color: $white;
      @include transition();

      &::before {
        content: '';
        z-index: -1;
        position: absolute;
        width: 100%;
        height: 24px;
        top: 0;
        left: 0;
        border-radius: 100px;
        display: block;

        @include transition($dur: 0.1s);
        @include gradient($from: $primary, $to: lighten($primary, 10));
      }
    }
  }

  &:hover {
    color: $white;
    box-shadow: 0 10px 50px rgba($black, 0.2);
    z-index: 50;

    .title {
      color: $white;
    }

    .more .arrow {
      transform: translateX(10px);

      &::before {
        opacity: 0;
      }
    }

    &::before {
      opacity: 1;
    }

    :global {
      .accent {
        color: $white;
      }
    }
  }
}
