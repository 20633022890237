@import url('https://fonts.googleapis.com/css?family=Rubik:400,500,700&display=swap');

@import 'assets/global';

body {
  @include gradient($from: $grey, $to: $grey, $deg: 300);
}

h1,
h2,
h3,
h4 {
  @include transition();
  font-weight: 400;
}

h1 {
  font-size: 1.6rem;
}

h2 {
  font-size: 1.2rem;
}

.ant-btn-round {
  font-size: 1rem;
}

p:empty {
  display: none;
}