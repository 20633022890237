@import './mixins';

$primary: #45a079;
$black: #393e47;
$grey: #f4f5f8;
$white: #fff;

.ant-spin {
  width: 100%;
  text-align: center;
}

form > div .field-object {
  border-bottom: 1px solid #ccc;
  margin-bottom: 24px;
  &:last-of-type {
    border: none;
  }
}
